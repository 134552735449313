import React, { Component } from "react";

class MeetTheTeam extends Component {
    render() {
        return(
            <div className="meetus u-margin-bottom-medium" id="meettheteam">
                <div className="row">
                    <div className="col-1-of-2">
                        <div className="meetus__imgbox">
                            <img src={require("../img/meetus2.webp")} alt="Meet Our Lawyers" className="meetus__img" />
                        </div>
                    </div>
                    <div className="col-1-of-2">
                        <div className="meetus__textsection"> 
                            <div className="meetus__header">
                                At Larry Thomas, we’re focused on you.
                            </div>

                            <div className="meetus__text">
                                At a large firm, it is all too easy to feel lost. At Larry Thomas, we promise to listen to your concerns and ideas,
                                to seek to understand your business and legal needs, and to offer creative and practical solutions in a cost-effective manner.
                            </div>

                            <a href="#login" className="btn btn--blue btn--animated">Book Us</a>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default MeetTheTeam;