import React, { Component } from "react";
import { FcWorkflow } from "react-icons/fc";
import { FcRules } from "react-icons/fc";
import { FcOk } from "react-icons/fc";
import { FcShop } from "react-icons/fc";
import { FcDataEncryption } from "react-icons/fc";

class Services extends Component {
    render() {
        return(
        <div className="services u-margin-bottom-huge" id="services">
            <div className="row row__break">
                <div className="col-1-of-4 col-1-of-1">
                    <div className="services__item">
                        <FcWorkflow className="services__svg"/>
                        <div className="services__header">
                            Business Organization
                        </div>
                        <div className="services__text">
                            We have the expertise and experience to make sure your business is organized in just the right way.
                        </div>
                   </div>
                </div>
                <div className="col-1-of-4 col-1-of-1">
                <div className="services__item">
                        <FcRules className="services__svg"/>
                        <div className="services__header">
                        CORPORATE FORMALITIES
                        </div>
                        <div className="services__text">
                            We have the expertise and experience to make sure your business is organized in just the right way.
                        </div>
                   </div>
                </div>
                <div className="col-1-of-4 col-1-of-1">
                <div className="services__item">
                        <FcOk className="services__svg"/>
                        <div className="services__header">
                        LICENSING SERVICES
                        </div>
                        <div className="services__text">
                            Our office can help you maintain essential corporate documents.
                        </div>
                   </div>
                </div>
                <div className="col-1-of-4 col-1-of-1">
                <div className="services__item">
                        <FcShop className="services__svg"/>
                        <div className="services__header">
                            PROPERTY TRANSACTIONS
                        </div>
                        <div className="services__text">
                            We have extensive experience in negotiating, drafting, and preparing commercial property agreements.
                        </div>
                   </div>
                </div>
            </div>
        </div> 
        );
    }
}

export default Services;