import React, { Component } from "react";

class Footer extends Component {
    render() {
        return (
            <footer className="footer">

                <div className="footer__logo-box" id="footer">
                    <img src={require("../img/ltlogo2.png")} alt="Full Logo" className="footer__logo" />
                </div>

                <div className="row row__footer">
                    <div className="col-1-of-2 col-1-of-1 no-border">
                        <div className="footer__navigation">
                            <ul className="footer__list">
                                <li className="footer__item"><a href="" className="footer__link">Company</a></li>
                                <li className="footer__item"><a href="" className="footer__link">Contact Us</a></li>
                                <li className="footer__item"><a href="" className="footer__link">Careers</a></li>
                                <li className="footer__item"><a href="" className="footer__link">Privacy Policy</a></li>
                                <li className="footer__item"><a href="" className="footer__link">Terms</a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-1-of-2 col-1-of-1 no-border">
                        <p className="footer__copyright">
                            Prempeh II Street,<br />
                            Cantoments 6QV. <br />
                            <span className="footer__phonenumber"> +233 200557665 </span> <br />
                            <span className="footer__phonenumber">
                                <a href="mailto: support@ltattorneys.org" style={{ color: 'lightblue' }}>
                                    support@ltattorneys.org
                                </a>
                            </span><br />
                            Copyright &copy; by Larry Thomas Inc.
                        </p>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;