import React, {Component} from "react";
import _ from "lodash";

class ImageSlide extends Component {
    constructor(props) {
        super(props);
        this.state = {
            images : [
                require('../img/slide1.jpg'),
                require('../img/slide2.webp'),
                require('../img/slide3.jpg'),
                require('../img/slide4.avif'),
                require('../img/slide5.avif'),
            ],
            numberLine: {
                1: {
                  end: 0,
                }
              }
        }
    }

    componentDidMount() {
        this.imageCounter();
    }

    imageCounter() {
        const that = this;

        function imageTimer() {
            const copy = _.cloneDeep(that.state.numberLine);
            let currentValue = that.state.numberLine["1"].end;
            if (currentValue >= 4 ) {
                currentValue = 0;
            } else {
                currentValue++;
            }
            
            copy["1"].end = currentValue;
            that.setState({
                numberLine: copy
              });
              //    console.log(that.state.numberLine);
        }
        setInterval(imageTimer, 5000);
    }


    render() {
        return(
            <div className="imageContainer" id="imageslide">
                <img src={this.state.images[`${this.state.numberLine["1"].end}`]} alt="Lawyers and Attorneys" className="slideimage"/>
            </div>
        )
    }
}

export default ImageSlide;