import React, { Component } from "react";
import emailjs from "@emailjs/browser";

class BookUs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            spinnerState: false,
        }
    }



    handleSubmit = (event) => {
        event.preventDefault();

        //  this.setState({ spinnerState: true });

        let email = event.target[0].value;
        let fullName = event.target[1].value;
        let message = event.target[2].value;

       //  console.log(email);
        // console.log(fullName);
        // console.log(message);

        const templateParams = {
            name: fullName,
            email: email,
            message: message,
        };

        emailjs.send('service_a40hq0f', 'template_a78oc46', templateParams, 'QoJexuMQ7Uc8mDSaV')
            .then((result) => {
                console.log("Message sent");
                setTimeout(()=> {
                    window.location.reload();
                }, 1000)

            }, (error) => {
                console.log(error.text);
            });
        }

    render() {
        const { spinnerState } = this.state;
        return (
            <section className="section-book" id="login">
                <div className="row">
                   
                    <div>
                        <div className="book book--login u-margin-bottom-medium">
                            <form action="#" className="book__content" onSubmit={(e) => this.handleSubmit(e)}>
                                
                                    <h2 className="heading-secondary book__header">
                                        Send Us A Message
                                    </h2>


                                <div className="form__group">
                                    <input type="email" className="form__input" required placeholder="Email" id="email" />
                                    <label htmlFor="email" className="form__label">Email</label>
                                </div>

                                <div className="form__group">
                                    <input type="text" className="form__input" required placeholder="Full Name" id="fullname" />
                                    <label htmlFor="fullname" className="form__label">Full Name</label>
                                </div>

                                <div className="form__group">
                                    <input type="text" className="form__input" required placeholder="Message" id="message" multiple/>
                                    <label htmlFor="message" className="form__label">Message</label>
                                </div>

                                <div className="form__group">
                                    <button type="submit" className="btn btn--blue">Send Message</button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>
        );
    }
}
export default BookUs; 
