import React from 'react'
import User from '../img/user.png';

const ReviewArray =
    [
        {
            "name": "James Nketiah",
            "date": "June 2022",
            "review": "I had the privilege of being assigned Adam Pierce by this outstanding law firm to help me with a complex land dispute. From the moment I met Adam, I knew I was in capable hands. His expertise, dedication, and unwavering commitment to my case were truly remarkable."
        },
        {
            "name": "Asomah Emmanuel",
            "date": "August 2021",
            "review": "Larry Thomas Attorneys provided me with exceptional legal services for my business needs. Their team's knowledge of corporate law and attention to detail ensured that all my legal matters were handled with precision and care. I highly recommend their services."
        },
        {
            "name": "George Amaniampong",
            "date": "October 2019",
            "review": "I recently used the services of Larry Thomas Attorneys for a real estate transaction. I was impressed with their professionalism and efficiency throughout the process. They made a complex legal matter feel simple. Thank you!"
        },
        {
            "name": "Steven Adjei",
            "date": "December 2018",
            "review": "I was facing a challenging family law case, and Larry Thomas Attorneys provided me with the legal support I needed. They showed empathy and dedication to resolving my case. I can't thank them enough for their guidance and support during this difficult time."
        }
    ]

function Reviews() {
    return (
        <div className='review'>

            {
                ReviewArray.map((item, index) => {
                    return (
                        <ReviewItem
                            name={item.name}
                            date={item.date}
                            reviewText={item.review}
                        />
                    )
                })
            }




        </div>
    )
}

const ReviewItem = (props) => {
    return (
        <div className="review__item">
            <div className="review__item__head">
                <img src={User} alt="User avatar" />
                <div className="review__item__head__details">
                    <p className="review__item__head__detail__name">{props.name}</p>
                    <div className="review__item__head__detail__date">{props.date}</div>
                </div>
            </div>
            <div className="review__item__body">
                {props.reviewText}
            </div>
        </div>
    )
}




export default Reviews