import React from 'react';
import logo from './logo.svg';
import './App.scss';
import emailjs from "@emailjs/browser";

import {Header,
        ImageSlide,
        AboutUs,
        LocateUs,
        Services,
        MeetTheTeam,
        BookUs,
        Footer,

    } from "./home";
import Reviews from './home/Reviews';

function App() {
  emailjs.init('QoJexuMQ7Uc8mDSaV');
  return (
    <div className="App">
      <html lang="en">
        <head>
          <meta charSet="UTF-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
          <link href="https://fonts.googleapis.com/css?family=Sora:100,300,400,700,900" rel="stylesheet" />
        </head>

        <body>
          <Header />
          <ImageSlide />
          <AboutUs />
          <LocateUs />
          <Services />
          <MeetTheTeam />
          <Reviews />
          <BookUs />
          <Footer />
        </body>
      </html>

    </div>
  );
}

export default App;
