import React, { Component } from "react";

class AboutUs extends Component {
    render() {
        return(
            <div className="aboutus u-margin-bottom-medium" id="aboutus">
                <p className="aboutus__heading-primary">
                    Discover the Larry Thomas Law Firm, The very best and reliable Law Firm in Ghana.
                </p>
                <p className="aboutus__text">
                Welcome to Larry Thomas, LLP, your trusted legal partner in Ghana. 
                Established in 2002, we have proudly served our community for over two decades, offering a comprehensive range of legal services to meet your every need. 
                Our commitment to excellence, integrity, and professionalism has earned us a reputation as one of the leading law firms in the region. 
                Whether you require expert guidance in business law, personal injury, 
                real estate, or any other legal matter, our experienced team of attorneys is here to provide you with the highest quality of legal representation. 
                At Larry Thomas, LLP, we are dedicated to delivering tailored solutions and achieving the best possible outcomes for our clients. 
                Your legal concerns are our priority, and we look forward to serving you with the utmost diligence and expertise.
                </p>
                <a href="#services" className="btn btn--blue btn--animated">Discover our Tools</a>
            </div>
        );
    }
}

export default AboutUs;