import React, { Component } from "react";

class LocateUs extends Component {
    render() {
        return(
        <div className="locateus u-margin-bottom-huge" id="locateus">
            <div className="row row__locate">
                <div className="col-1-of-3 col__sub">
                    <div className="firstsection">
                        <img src={require("../img/locate1.jpg")} alt="Our Location in UK" className="locateus__img"/>
                    </div>
                </div>
                <div className="col-1-of-3 col__main">
                    <div className="middlesection">
                        <div className="middlesection__box">
                            <div className="middlesection__textcontainer">
                                <div className="middlesection__boxtext">
                                Your business is our business. We offer the best administrations and our customers are consistently cheerful
                                </div>
                            </div>
                            <a href="#footer" className="btn btn--blue btn--animated middlesection__btn">Contact Us</a>
                        </div>
                    </div>
                </div>
                <div className="col-1-of-3 col__sub">
                    <div className="lastsection">
                        <img src={require("../img/locate2.jpg")} alt="Our Location in UK" className="locateus__img"/>
                    </div> 
                </div>
            </div>
        </div> 
        );
    }
}

export default LocateUs;