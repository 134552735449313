import React, { Component } from "react";
import { FcBusinessman } from "react-icons/fc";
import { FcApproval } from "react-icons/fc";
import { FcCellPhone } from "react-icons/fc";
import Mail from '../img/message.png'

class Header extends Component {
    render() {
        return (
            <div className="header" id="header">
                <div className="row">
                    <div className="col-1-of-3">
                        <div className="iconSection">
                            <img src={require("../img/ltlogo2.png")} alt="logo of the company" className="logo" />
                        </div>
                    </div>
                    <div className="col-1-of-3">
                        <div className="textSection">
                            <div className="mainText">
                                LARRY <span className="linethrough">| </span> THOMAS
                            </div>
                            <div className="subText">
                                ATTORNEYS AT LAW
                            </div>
                        </div>
                    </div>
                    <div className="col-1-of-3">
                        <div className="contactSection">
                            <a href="mailto: support@ltattorneys.org">
                                <img src={Mail} alt="Mail Icon" className="fonticon" />
                            </a>

                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default Header;